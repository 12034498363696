import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import documentSagas from './document/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    documentSagas()
  ]);
}
