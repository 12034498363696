import * as actionTypes from '../actions';

const INIT_STATE = {
    document: {},
    documents: [],
    creditNote: {},
    debitNote: {},
    invoice: {},
    loading: false,
    redirect: false,
    error: ''
};

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case actionTypes.INVOICE_RESET:
            return { ...state, loading: false, invoice: {}, document: {} };
        case actionTypes.INVOICE_PUT:
            return { ...state, loading: false, document: action.document, invoice: action.document };
        case actionTypes.CREDITNOTE_PUT:
            return { ...state, loading: false, creditNote: action.document };
        case actionTypes.DEBITNOTE_PUT:
            return { ...state, loading: false, debitNote: action.document };
        case actionTypes.DOCUMENT_PENDING_API_GET:
            return { ...state, loading: true };
        case actionTypes.DOCUMENT_PENDING_API_GET_SUCCESS:
            return { ...state, loading: false, document: action.document };
        case actionTypes.DOCUMENT_PENDING_API_GET_ERROR:
            return { ...state, loading: false, error: action.message };
        case actionTypes.DOCUMENT_API_GET:
            return { ...state, loading: true, redirect: true };
        case actionTypes.DOCUMENT_API_GET_SUCCESS:
            if (action.result.totalItems === 1) {
                return { ...state, loading: false, invoice: action.result.list[0], document: action.result.list[0], redirect: false };
            } else {
                return { ...state, loading: false, redirect: false };
            }
        case actionTypes.DOCUMENT_API_GET_ERROR:
            return { ...state, loading: false, error: action.message, redirect: false };
        case actionTypes.DOCUMENT_API_PUT:
            return { ...state, loading: true };
        case actionTypes.DOCUMENT_API_PUT_SUCCESS:
            return { ...state, loading: false, document: action.document };
        case actionTypes.DOCUMENT_API_PUT_ERROR:
            return { ...state, loading: false, error: action.message };
        default: return state;
    }
}

export default reducer