import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { posApi } from "../../constant/defaultValues";
import { getQueryUrl } from "../../helper/Utils"

import {
    DOCUMENT_API_GET,
    DOCUMENT_PENDING_API_GET,
    DOCUMENT_API_PUT,
} from '../actions';

import {
    documentPendingGetApiError,
    documentPendingGetApiSuccess,
    documentGetApiError,
    documentGetApiSuccess,
    documentPutApiError,
    documentPutApiSuccess,
} from './actions';

export function* watchDocumentPendingGet() {
    yield takeEvery(DOCUMENT_PENDING_API_GET, ApiDocumentPendingGet);
}

export function* watchDocumentGet() {
    yield takeEvery(DOCUMENT_API_GET, ApiDocumentGet);
}

export function* watchDocumentPut() {
    yield takeEvery(DOCUMENT_API_PUT, ApiDocumentPut);
}

function* ApiDocumentPendingGet({ parameters }) {
    try {
        const documentGet = yield call(ApiDocumentPendingGetAsync, parameters);
        if (!documentGet.message) {
            yield put(documentPendingGetApiSuccess(documentGet));
        } else {
            yield put(documentPendingGetApiError(documentGet.message));
        }
    } catch (error) {
        yield put(documentPendingGetApiError(error));
    }
}

function* ApiDocumentGet({ parameters }) {
    try {
        const documentGet = yield call(ApiDocumentGetAsync, parameters);
        if (!documentGet.message) {
            yield put(documentGetApiSuccess(documentGet));
        } else {
            yield put(documentGetApiError(documentGet.message));
        }
    } catch (error) {
        yield put(documentGetApiError(error));
    }
}

function* ApiDocumentPut({ document }) {
    try {
        const documentPut = yield call(ApiDocumentPutAsync, document);
        if (!documentPut.message) {
            yield put(documentPutApiSuccess(document));
        } else {
            yield put(documentPutApiError(documentPut.message));
        }
    } catch (error) {
        yield put(documentPutApiError(error));
    }
}

const ApiDocumentPendingGetAsync = async (options) =>
    await fetch(`${posApi.endpoint.documentPending}?${getQueryUrl(options)}`, {
        method: 'GET',
        headers: new Headers(posApi.header)
    })
        .then(document => document.json()).catch(error => error)

const ApiDocumentGetAsync = async (options) =>
    await fetch(`${posApi.endpoint.document}?${getQueryUrl(options)}`, {
        method: 'GET',
        headers: new Headers(posApi.header)
    })
        .then(document => document.json()).catch(error => error)

const ApiDocumentPutAsync = async (document) => {
    let header = { ...posApi.header }
    header['Content-Type'] = 'application/json;charset=UTF-8';
    header['Accept'] = 'application/json, text/plain';

    return await fetch(`${posApi.endpoint.document}`, {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(document),
        headers: new Headers(header)
    })
        .then(document => document.json()).catch(error => error)
}

export default function* rootSaga() {
    yield all([
        fork(watchDocumentPendingGet),
        fork(watchDocumentGet),
        fork(watchDocumentPut),
    ]);
}