import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { posApi, defaultColor } from '../../constant/defaultValues';
import { getQueryUrl, getMenuPathInit } from '../../helper/Utils';

import { LOGIN_USER, LOGOUT_USER } from '../actions';

import { loginUserSuccess, loginUserError } from './actions';

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (user, password) => {
  return await fetch(
    `${posApi.endpoint.login}?${getQueryUrl({
      username: user,
      password: password
    })}`,
    { headers: new Headers(posApi.header) }
  )
    .then((authUser) => authUser.json())
    .catch((error) => error);
};

function* loginWithEmailPassword({ payload }) {
  const { user, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, user, password);
    if (!loginUser.message) {
      loginUser.pos = loginUser.userPos[0].pos;
      let cloneLoginUser = { ...loginUser };
      delete cloneLoginUser.userPos;

      let theme = cloneLoginUser.pos.sales_office.style || 'aje',
        nameOffice = cloneLoginUser.pos.sales_office.name.toUpperCase();

      if (nameOffice.includes('MONTBLANC')) {
        theme = 'montblanc';
      }

      if (nameOffice.includes('TUMI')) {
        theme = 'tumi';
      }

      localStorage.setItem('user', JSON.stringify(cloneLoginUser));
      localStorage.setItem('__theme_color', theme);
      yield put(loginUserSuccess(cloneLoginUser));
      history.push(getMenuPathInit(cloneLoginUser));
      window.location.reload();
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* logout({ payload }) {
  const { history } = payload;
  try {
    yield localStorage.removeItem('user');
    yield localStorage.setItem('__theme_color', defaultColor);
    history.push('/');
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}
