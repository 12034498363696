import {
  INVOICE_RESET,
  INVOICE_PUT,
  DOCUMENT_PENDING_API_GET,
  DOCUMENT_PENDING_API_GET_SUCCESS,
  DOCUMENT_PENDING_API_GET_ERROR,
  DOCUMENT_API_GET,
  DOCUMENT_API_GET_SUCCESS,
  DOCUMENT_API_GET_ERROR,
  DOCUMENT_API_PUT,
  DOCUMENT_API_PUT_SUCCESS,
  DOCUMENT_API_PUT_ERROR,
  CREDITNOTE_PUT,
  DEBITNOTE_PUT
} from '../actions';

export const documentPendingGetApi = (parameters) => ({
  type: DOCUMENT_PENDING_API_GET, parameters
});

export const documentPendingGetApiSuccess = document => ({
  type: DOCUMENT_PENDING_API_GET_SUCCESS, document
});

export const documentPendingGetApiError = message => ({
  type: DOCUMENT_PENDING_API_GET_ERROR, message
});

export const documentGetApi = (parameters) => ({
  type: DOCUMENT_API_GET, parameters
});

export const documentGetApiSuccess = result => ({
  type: DOCUMENT_API_GET_SUCCESS, result
});

export const documentGetApiError = message => ({
  type: DOCUMENT_API_GET_ERROR, message
});

export const documentPutApi = (document) => ({
  type: DOCUMENT_API_PUT, document
});

export const documentPutApiSuccess = document => ({
  type: DOCUMENT_API_PUT_SUCCESS, document
});

export const documentPutApiError = message => ({
  type: DOCUMENT_API_PUT_ERROR, message 
});

export const invoicePut = (document) => ({
  type: INVOICE_PUT, document
});

export const invoiceReset = () => ({
  type: INVOICE_RESET
});

export const creditNotePut = (document) => ({
  type: CREDITNOTE_PUT, document
});

export const debitNotePut = (document) => ({
  type: DEBITNOTE_PUT, document
});