/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
import { encode } from 'base-64';
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const defaulCurrency = '$';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' }
];

// export const posApiUrl = `http://${window.location.hostname}:9099`;
export const posApiUrl = process.env.REACT_APP_API_POS_URL;

export const posApi = {
  // url: process.env.REACT_APP_API_POS_URL,
  url: posApiUrl,
  header: {
    Authorization:
      'Basic ' +
      encode(process.env.REACT_APP_API_POS_TOKEN + ':' + process.env.REACT_APP_API_POS_KEY)
  },
  endpoint: {
    app: `${posApiUrl}/app/`,
    user: `${posApiUrl}/user/`,
    login: `${posApiUrl}/user/login/`,
    data: `${posApiUrl}/data/`,
    product: `${posApiUrl}/product/`,
    document: `${posApiUrl}/document/`,
    documentComplete: `${posApiUrl}/document/complete`,
    documentPending: `${posApiUrl}/document/pending`,
    documents: `${posApiUrl}/documents/`,
    seller: `${posApiUrl}/seller/`,
    customer: `${posApiUrl}/customer/`,
    pinpad: `${posApiUrl}/pinpad/`,
    directa: `${posApiUrl}/directa/`,
    surcharge: `${posApiUrl}/surcharge/`,
    creditcard: `${posApiUrl}/creditcard/`,
    template: `${posApiUrl}/template/`,
    promotion: `${posApiUrl}/promotion/`,
    campaign: `${posApiUrl}/campaign/`,
    vtex: `${posApiUrl}/vtex/`
  }
};

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = '__theme_color';
export const isMultiColorActive = true;
export const defaultColor = 'aje';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const documentType = {
  PN: { id: 'PN', name: 'Recaudo Plan Novios' },
  FA: { id: 'FA', name: 'Factura' },
  PY: { id: 'PY', name: 'Pagos' }
};

export const documentStatus = {
  PENDING: 'Pendiente',
  FAILED: 'Fallido',
  PROCESSING: 'Procesando',
  COMPLETED: 'Completado',
  REFUNDED: 'Reembolso',
  CANCELLED: 'Cancelado',
  OFFLINE: 'Fuera de linea'
};

export const paymentType = {
  W: 'Efectivo',
  V: 'Cheque',
  Y: 'Tarjeta de Crédito - Autorización Automática',
  F: 'Tarjeta de Crédito - Autorización Manual',
  I: 'Directa - Autorización Automática',
  J: 'Directa - Autorización Manual',
  X: 'Transferencia'
};

export const posType = {
  STILL: 'STILL',
  MOBILE: 'MOBILE'
};
