// import crypto  from "crypto";
import { defaultDirection } from '../constant/defaultValues';
import IntlMessages from '../helper/IntlMessages';
import menuItemsData from '../constant/menu';
import _ from 'lodash';

// const algorithm = 'aes-256-ctr';
// const secretKey = 'vOVE6sLmpJWjURIRCcIrdxs01lwHzfr3';
// const iv = crypto.randomBytes(16);

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return {
    day: now.getDate(),
    month_id: now.getMonth() + 1,
    // eslint-disable-next-line react/react-in-jsx-scope
    month_name: <IntlMessages id={`month.${now.getMonth()}`} />,
    year: now.getFullYear()
  };
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl'
  };
};

export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const getQueryUrl = (parameters) => {
  return Object.keys(parameters)
    .map((key) => `${key}=${parameters[key]}`)
    .join('&');
};

export const setTax = (price, tax, round = 2) => {
  let priceTax = price;
  if (tax && tax > 0.0) {
    priceTax = priceTax * (tax + 1);
  }
  return parseFloat(priceTax).toFixed(round);
};

export const setCapitalize = (str) => {
  return str ? str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase()) : str;
};

export const numberFormat = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

export const isLetter = (evt) => {
  var charCode = evt.which ? evt.which : evt.KeyCode;
  return (
    (charCode >= 65 && charCode <= 90) ||
    (charCode >= 97 && charCode <= 122) ||
    charCode === 32 ||
    charCode === 241
  );
};

export const isAlphanumeric = (e) => {
  var specialKeys = [];
  specialKeys.push(8); // Backspace
  specialKeys.push(9); // Tab
  specialKeys.push(46); // Delete
  specialKeys.push(36); // Home
  specialKeys.push(35); // End
  specialKeys.push(37); // Left
  specialKeys.push(39); // Right

  var keyCode = e.keyCode === 0 ? e.charCode : e.keyCode,
    ret =
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 65 && keyCode <= 90) ||
      keyCode === 32 ||
      (keyCode >= 97 && keyCode <= 122) ||
      (specialKeys.indexOf(e.keyCode) !== -1 && e.charCode !== e.keyCode);
  return ret;
};

export const isDecimal = (e, field) => {
  let key = e.keyCode ? e.keyCode : e.which,
    regexp;
  if (key === 8) return true;
  if (key > 47 && key < 58) {
    if (field.value === '') return true;
    regexp = /.[0-9]{9}$/;
    return !regexp.test(field.value);
  }
  if (key === 46) {
    if (field.value === '') return false;
    regexp = /^[0-9]+$/;
    return regexp.test(field.value);
  }
  if (key === 9) return true;
  return false;
};

export const isNumber = (e) => {
  var key = e.keyCode === 0 ? e.charCode : e.keyCode;
  return key <= 13 || (key >= 48 && key <= 57);
};

export const isPassword = (e) => {
  var key = e.keyCode === 0 ? e.charCode : e.keyCode,
    regex = /^[A-Za-z0-9]+$/;
  return regex.test(String.fromCharCode(key));
};

export const validateCI = (ci) => {
  let isNumeric = true;
  let total = 0,
    individual;

  for (let position = 0; position < 10; position++) {
    individual = ci.toString().substring(position, position + 1);

    if (isNaN(individual)) {
      isNumeric = false;
      break;
    } else {
      if (position < 9) {
        if (position % 2 === 0) {
          if (parseInt(individual) * 2 > 9) {
            total += 1 + ((parseInt(individual) * 2) % 10);
          } else {
            total += parseInt(individual) * 2;
          }
        } else {
          total += parseInt(individual);
        }
      }
    }
  }

  if (total % 10 !== 0) {
    total = total - (total % 10) + 10 - total;
  } else {
    total = 0;
  }

  if (isNumeric) {
    if (ci.toString().length != 10) {
      return false;
    }

    if (parseInt(ci, 10) == 0) {
      return false;
    }

    if (total != parseInt(individual)) {
      return false;
    }

    return true;
  }

  return false;
};

export const validateRUC = (document) => {
  let dto = document.length;
  let valor;
  let acu = 0;
  for (let i = 0; i < dto; i++) {
    valor = document.substring(i, i + 1);
    if (
      valor == 0 ||
      valor == 1 ||
      valor == 2 ||
      valor == 3 ||
      valor == 4 ||
      valor == 5 ||
      valor == 6 ||
      valor == 7 ||
      valor == 8 ||
      valor == 9
    ) {
      acu = acu + 1;
    }
  }
  if (acu == dto) {
    while (document.substring(10, 13) !== '001') {
      console.log('Los tres ultimos digitos no tienen el codigo del RUC 001');
      return false;
    }
    while (document.substring(0, 2) > 24) {
      console.log('Los dos  primeros digitos no pueden ser mayores a 24');
      return false;
    }
    let porcion1 = document.substring(2, 3);
    if (porcion1 < 6) {
      console.log('El tercer digito es menor a 6, por lo tanto el usuario es una persona natural');
      return validateCI(document.substring(0, 10));
    } else {
      return true;
      // if (porcion1 == 6) {
      //   console.log("El tercer digito es igual a 6, por lo tanto el usuario es una entidad publica");
      //   return verifyPublicSector(document);
      // } else {
      //   if (porcion1 == 9) {
      //     console.log("El tercer digito es igual a 9, por lo tanto el usuario es una sociedad privada");
      //     return verifyPrivateSector(document);
      //   }
      // }
    }
  }
};

function verifyPublicSector(ci) {
  let aux = 0,
    prod,
    veri;
  veri =
    parseInt(ci.substring(9, 10)) +
    parseInt(ci.substring(10, 11)) +
    parseInt(ci.substring(11, 12)) +
    parseInt(ci.substring(12, 13));
  if (veri > 0) {
    const coeficiente = [3, 2, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < coeficiente.length; i++) {
      prod = parseInt(ci.substring(i, i + 1)) * coeficiente[i];
      aux += prod;
    }

    if (aux % 11 == 0) {
      veri = 0;
    } else if (aux % 11 == 1) {
      return false;
    } else {
      aux = aux % 11;
      veri = 11 - aux;
    }

    if (veri == parseInt(ci.substring(8, 9))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function verifyPrivateSector(ci) {
  let aux = 0,
    prod,
    veri;
  veri =
    parseInt(ci.substring(10, 11)) +
    parseInt(ci.substring(11, 12)) +
    parseInt(ci.substring(12, 13));
  if (veri > 0) {
    const coefficient = [4, 3, 2, 7, 6, 5, 4, 3, 2];

    for (let i = 0; i < coefficient.length; i++) {
      prod = parseInt(ci.substring(i, i + 1)) * coefficient[i];
      aux += prod;
    }

    if (aux % 11 == 0) {
      veri = 0;
    } else if (aux % 11 == 1) {
      return false;
    } else {
      aux = aux % 11;
      veri = 11 - aux;
    }

    if (veri == parseInt(ci.substring(9, 10))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const getMenu = (access) => {
  var menuResult = _.cloneDeep(menuItemsData),
    newMenu = [];

  for (let i = 0; i < menuResult.length; i++) {
    let element = menuResult[i];
    if (access.includes(element.id)) {
      newMenu.push(element);
    } else {
      let subs = element.subs ? _.cloneDeep(element.subs) : [],
        subsNew = [];

      for (let a = 0; a < subs.length; a++) {
        if (access.includes(subs[a].id)) {
          subsNew.push(subs[a]);
        }
      }
      if (subsNew.length > 0) {
        element.subs = subsNew;
        newMenu.push(element);
      }
    }
  }
  return newMenu;
};

export const getMenuValidateNode = (path, user) => {
  let result = false;
  if (user) {
    const access = JSON.parse(user.access);
    const menuResult = getMenu(access);

    for (let i = 0; i < menuResult.length; i++) {
      let element = menuResult[i];
      if (element.to === path) {
        return true;
      } else {
        if (element.subs) {
          for (let a = 0; a < element.subs.length; a++) {
            if (element.subs[a].to === path) {
              return true;
            }
          }
        }
      }
    }
  }
  return result;
};

export const getMenuPathInit = (user) => {
  let path = '/';
  if (user) {
    const menuResult = getMenu(user.access);
    for (let i = 0; i < menuResult.length; i++) {
      let element = menuResult[i];
      if (element.id != 'USERPA') {
        if (element.subs) {
          for (let a = 0; a < element.subs.length; a++) {
            return element.subs[a].to;
          }
        } else {
          return element.to;
        }
      }
    }
  }
  return path;
};

// export const encrypt = (text) => {
//   const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
//   const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
//   return {
//       iv: iv.toString('hex'),
//       content: encrypted.toString('hex')
//   };
// };

// export const decrypt = (hash) => {
//   const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
//   const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);
//   return decrpyted.toString();
// };

export const roundTo = (n, digits) => {
  if (digits === undefined) {
    digits = 0;
  }

  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  var test = Math.round(n) / multiplicator;
  return +test.toFixed(digits);
};

export const roundPos = (value) => {
  let value2 = roundTo(value, 3);
  return parseFloat(roundTo(value2, 2));
};
