import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import { defaultColor, themeColorStorageKey } from "./constant/defaultValues";

const company =
  localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, company);

let render = () => {
  import('./assets/css/sass/company/pos.' + company + '.scss').then(x => {
    require('./AppRenderer');
  });
};

render();